import React from 'react';
import Helmet from 'react-helmet';
import {
    Link
  } from "react-router-dom";

import { Twitter, Instagram, YouTube, LinkedIn} from '@material-ui/icons';
import './contact-page.scss';

class ContactPage extends React.Component{

    constructor(Props){
        super(Props);

        this.state = {
            faq: [
            {
                question: "When is Project Mars being released?",
                answer: <p>
                            Project Mars will most likely release sometime in 2021, we hope to release Project Mars sometime within Q1 or Q2 of 2021." We also expect the first playable demo to be released in Q3 or Q4 of 2020.
                        </p>
            },
            {
                question: "What is the demo and how do we get access?",
                answer: <p>
                            The demo will be a 30 minute to 1 hour game meant to serve as an introduction to the gameplay mechanics and story of Project Mars. We will unfortunately only sending the demo to select participants!
                        </p>
            },
            {
                question: "What development tools do you use at Ctrlfyp?",
                answer: 
                <>
                    <h4>Game Development:</h4>
                    <p>
                         We use <a target="_blank" href="https://godotengine.org/">Godot</a> as our Game Engine. Our art assets were created using 
                        <a target="_blank" href="https://www.aseprite.org/">Aesprite</a>. We use a variety of open source tools such as <a target="blank" href="https://github.com/EmAlexander/DialogueTreePlugin">Dialogue Tree Plugin</a> to make our game.
                    </p>
                    <h4>Miscellaneous Development:</h4>
                    <p>
                        We use <a target="blank" href="https://reactjs.org/">React</a> as on our frontend and  <a target="blank" href="https://expressjs.com/">Express</a> on our backend. We use  
                        <a target="blank" href="https://www.latex-project.org/">LaTeX</a> to compile and create more complicated documents.
                    </p> 
                </>
            },
            {
                question: "Why use Godot, over more other game engines such as Unity, Unreal, or GameMaker?",
                answer: 
                <p>
                    <p> Due to the size of our game it came down to decision between Unity and Godot. Both engines we felt are very similar to each other differing mainly in maturity and pricing models.
                    We chose Godot because we felt that the idea of an open source game engine to be very intriguing, similar to other tools that are community developed and supported such as 
                    <a target="blank" href="https://reactjs.org/">React</a> or <a target="blank" href="https://matplotlib.org/">MatplotLib</a> we believe that Godot has a lot of potential as a engine for indie developers. In addition in terms of how both engines are organized and used, we found Godot to be extremely easy to learn with and without prior experience with Unity. However if are choosing what engine you are looking to use for yout game or program remember that ultimately the choice of which engine is up to you; be sure to balance out the pros and cons when it comes out which one to stick with! 
                    </p>
                </p>
            }
            ],
        }
    }

    /*
                        <div className="email-sidebar">
                        <h2>Contact us</h2>
                        <p>Have a question about our streaming or fan-merch policies? Want to pledge money? Looking to intern? We have some answers to frequently asked questions in our new FAQ section!</p>
                        <p>For other questions, or if you just want to chat, use the contact form below.</p>
                    </div>
    */

    render() {
        return (
            <div className="contact-page section">
                <Helmet>
                    <title>Ctrlfyp - Contact</title>
                </Helmet>
                <div className="intro-info">
                    <div className="intro">
                        <h1 className="title">Connect with us</h1>
                    </div>
                    <div className="intro-sidebar">
                        <h2>Contact us</h2>
                        <p>We're happy to be apart of a great community of people but whether you have a question about our studio or about our games we would love to answer them for you. But before you do check out some of the frequently asked questions in the FAQ section below!</p>
                        <p>For other questions, or if you just want to chat, reach out to us on social media or by email at <a href="mailto:contact@ctrlfyp.com">contact@ctrlfyp.com</a>.</p>
                    </div>
                    <div className="social-sidebar">
                        <h3>Social</h3>
                        <p>Follow us for quick updates, links and the odd joke on our very own social media pages. Additionally, use these to get in touch with us:</p>
                        <ul className="social-links">
                            <li>
                                <Link to="google.ca" className="twitter-link">
                                    <Twitter className="circle"></Twitter>
                                    <h3 className="link-name">Ctrlfyp on Twitter</h3>
                                </Link>
                            </li>
                            <li>
                                <Link to="google.ca" className="discord-link">
                                    <LinkedIn className="circle"></LinkedIn>
                                    <h3 className="link-name">Ctrlfyp on Linkedin</h3>
                                </Link>
                            </li>
                            <li>
                                <Link to="google.ca" className="instagram-link">
                                    <Instagram className="circle"></Instagram>
                                    <h3 className="link-name">Ctrlfyp on Instagram</h3>
                                </Link>
                            </li>
                            <li>
                                <Link to="google.ca" className="youtube-link">
                                    <YouTube className="circle"></YouTube>
                                    <h3 className="link-name">Ctrlfyp on Youtube</h3>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="faq">
                    <h2 className="subtitle">Frequently Asked Questions</h2>
                    <h3>Before you send an email about anything why not check our the faq right below here to see if it already asked.</h3>
                    <ul>
                        {this.state.faq.map((faq, index) => {
                            return (<li key={index}>
                                <h3>{faq.question}</h3>
                                <p>{faq.answer}</p>
                            </li>)
                        })}
                    </ul>
                </div>
            </div>
        );
    }
}

export default ContactPage;