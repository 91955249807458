import React from 'react';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";

import './footer.scss';


import EsrbLogo from '../../assets/imgs/esrb-rp-logo.jpg';
import SteamLogo from '../../assets/imgs/steam-logo.png';
import { Twitter, Instagram, YouTube, LinkedIn} from '@material-ui/icons';

class Footer extends React.Component{

    constructor(Props){
        super(Props);

        this.state = {

        }
    }

    render(){
        return (
            <Router>
                <div className="footer">
                    <div className="footer-social">
                        <div className="social-tag">Follow Us</div>
                        <ul className="social-links">
                            <li>
                                <a target="_blank" href="https://google.ca" rel="noopener noreferrer" className="twitter-link">
                                    <Twitter className="circle"></Twitter>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://google.ca" rel="noopener noreferrer" className="discord-link">
                                    <LinkedIn className="circle"></LinkedIn>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://google.ca" rel="noopener noreferrer" className="instagram-link">
                                    <Instagram className="circle"></Instagram>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://google.ca" rel="noopener noreferrer" className="youtube-link">
                                    <YouTube className="circle"></YouTube>
                                </a>
                            </li>
                        </ul>
                        <div className="social-tag">Contact Us</div>
                    </div>
                    <div className="footer-ratings">
                        <img className="esrb-logo" src={EsrbLogo} alt=""></img>
                        <img className="steam-logo" src={SteamLogo} alt=""></img>
                    </div>
                    <div className="footer-disclaimer">
                        {(new Date()).getFullYear()} Ctrlfyp, Inc. All rights reserved.
                        All trademarks referenced herein are the properties of their respective owners. Ctrlfyp is a registered company in Canada.
                    </div>
                    <ul className="footer-legal">
                        <li>
                            <Link to="google.ca" className="discord-link">{'Terms of Service'.toUpperCase()}</Link>
                        </li>
                        <li>
                            <Link to="google.ca" className="instagram-link">{'Cookies and Privacy'.toUpperCase()}</Link>
                        </li>
                        <li>
                            <Link to="google.ca" className="youtube-link">{'Direct Contact'.toUpperCase()}</Link>
                        </li>
                    </ul>
                </div>
            </Router>
            )
    }
}

export default Footer;