import React from 'react';
import Helmet from 'react-helmet';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import '../../../../services/api/jobs-api';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
  } from "react-router-dom";


import JobDescription from '../job-description/job-description';
import { GetJobs, GetJobCount, GetDepartments, GetEmploymentStatuses } from '../../../../services/api/jobs-api';
import './careers-page.scss';

class CareersPage extends React.Component{

    constructor(Props){
        super(Props);

        this.state = {
            jobs: [],
            totalJobCount: 0,
            departments: [],
            employmentStatuses: [],

            selectedDepartment: "",
            selectedStatus: "",
        }
    }

    componentDidMount = () => {
        this.loadJobs();
    }

    loadJobs = () => {
        this.setState({
            jobs: GetJobs(),
            totalJobCount: GetJobCount(),
            departments: GetDepartments(),
            employmentStatuses: GetEmploymentStatuses(),
        });
    }

    onSelectedDepartmentChange = (e) => {
        this.setState({
            selectedDepartment: e.target.value,
        });
    }

    onSelectedStatusChange = (e) => {
        this.setState({
            selectedStatus: e.target.value,
        });
    }


    render() {
        return (
            <Router>
                <div className="careers-page section">
                    <Switch>
                        <Route exact path="/careers">
                            <Helmet>
                                <title>Ctrlfyp - Careers</title>
                            </Helmet>
                            <div className="heading">
                                <h1>Work With Us</h1>
                                <h3>At Ctrlfyp</h3>
                                <p>
                                Ctrlfyp is a small sized indie studio located in Toronto aimed at creating some games faithful to the classic metroidvania genre.
                                </p>
                                <p>
                                You feel it on your first day, while meeting countless team members who want to know about your journey, YOUR story. You feel it in your first week, while being quickly immersed into a mythical book (personalized with your name) about the vision of our next project. You feel it the first time you struggle with something and you quickly realize that you have a wide array of supportive people around you. You feel it when you learn how diverse the interests are of everyone under this roof, yet you see how we all make a point to actively engage in these interests with one another. Regardless of the path you take to get here, from the moment you start life at Santa Monica Studio, you’ll see that it feels like home and that the team here is, more than anything, like creative family.
                                </p>
                            </div>
                            <div className="work-culture">

                            </div>
                            <div className="job-table">
                                <div className="table-control">
                                    <h2 className="job-table-title">Openings ({this.state.totalJobCount}) </h2>
                                    <div className="job-table-selectors">
                                        <FormControl className="department-dropdown">
                                            <InputLabel>Department</InputLabel>
                                            <Select
                                            value={this.state.selectedDepartment}
                                            onChange={this.onSelectedDepartmentChange}
                                            >
                                            {
                                                this.state.departments.map((department) => {
                                                        return (
                                                            <MenuItem key={department} value={department}>{department}</MenuItem>
                                                        )
                                                    }
                                                )

                                            }
                                            </Select>
                                        </FormControl>
                                        <FormControl className="employment-status-dropdown">
                                            <InputLabel>Status</InputLabel>
                                            <Select
                                            value={this.state.selectedStatus}
                                            onChange={this.onSelectedStatusChange}
                                            >
                                            {
                                                this.state.employmentStatuses.map((employmentStatus) => {
                                                        return (
                                                            <MenuItem key={employmentStatus} value={employmentStatus}>{employmentStatus}</MenuItem>
                                                        )
                                                    }
                                                )

                                            }
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <Table>
                                    <caption>Due to restraints only successful applicants will be contacted.</caption>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Title</TableCell>
                                            <TableCell align="right">Team</TableCell>
                                            <TableCell align="right">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.jobs.map((job) => {
                                                return (
                                                    <TableRow key={job.id}>
                                                        <TableCell><Link className="job-link" to={"/careers/jobs/"+ job.id}>{job.title}</Link></TableCell>
                                                        <TableCell align="right">{job.department}</TableCell>
                                                        <TableCell align="right">{job.employmentStatus}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                            <div className="careers-perks">

                            </div>
                        </Route>
                        <Route path="/careers/jobs/" render={(location) => {
                            let pathname = location.location.pathname.split("/");
                            let id = pathname[pathname.length - 1];
                            return (
                                <JobDescription jobId={id}></JobDescription>
                            );
                        }}> 
                        </Route>
                        <Route><Redirect to="/careers"></Redirect></Route>
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default CareersPage;