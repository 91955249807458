import React from 'react';
import Helmet from 'react-helmet';

import './about-page.scss';

import CtrlfypBanner from '../../../../assets/imgs/ctrlfyp-logo.png';
import JohnImage from '../../../../assets/imgs/john.png';
import WorkingImage from '../../../../assets/imgs/working.jpg';

class AboutPage extends React.Component{

    constructor(Props){
        super(Props);

        this.state = {
            
        }
    }

    render() {
        return (
            <div className="about-page section">
                <Helmet>
                    <title>Ctrlfyp - About Us</title>
                    
                </Helmet>
                <div className="about-header">
                    <div className="summary">
                        <h1 className="title">Who Are We</h1>
                        <p className="summary-description">
                        You feel it on your first day, while meeting countless team members who want to know about your journey, YOUR story. You feel it in your first week, while being quickly immersed into a mythical book (personalized with your name) about the vision of our next project. You feel it the first time you struggle with something and you quickly realize that you have a wide array of supportive people around you. You feel it when you learn how diverse the interests are of everyone under this roof, yet you see how we all make a point to actively engage in these interests with one another. Regardless of the path you take to get here, from the moment you start life at Santa Monica Studio, you’ll see that it feels like home and that the team here is, more than anything, like creative family.
                        </p>
                        <p>
Our studio thrives on a culture built around connection. That is truly what family means. It's how we have fun and make great games together. Team-voted movie nights, chill and grill happy hours, life drawing events to hone your artistic expression.
                        </p>
                    </div> 
                    <div className="banner">
                        <img src={WorkingImage}></img>
                        
                        <img src={CtrlfypBanner}></img>
                        
                        <img src={CtrlfypBanner}></img>
                        
                        <img src={CtrlfypBanner}></img>
                    </div> 
                </div>
                <div className="team">
                    <div className="team-info">   
                        <h5 className=" caption">Our Process</h5>
                        <h3 className="subtitle team-subtitle">We work using a human-centered innovation loop</h3>
                        <p>Launching the most important game in our studio history led with a week to remember honoring our team. Monday started with a special Norse keepsake for all team members, followed Tuesday by a God of War Gear discounted pop-up store. On Wednesday we had an intense God of War PlayStation Pro Bundle raffle throughout the day, then Thursday everyone received and signed each others God of War Collector Edition and art books. The evening of our launch we gathered on the deck as the official PlayStation Countdown to Launch live show with our cast and leads took place inside. Friday, launch day, everything culminated together with a tears of joy block ceremony.</p>
                    </div>
                    <div className="image-wrapper">
                        <img className="team-image" src={WorkingImage}></img>
                    </div>
                </div>
                <div className="founders">
                    <div className="team-info">   
                        <h5 className=" caption">Our Process</h5>
                        <h3 className="subtitle team-subtitle">The Team</h3>
                        </div>
                    <div className="founder-john founder">
                        <div className="portrait-wrapper">
                            <img className="portrait" src={JohnImage}></img>
                        </div>
                        <div className="founder-info">
                            <h3 className="founder-name">John Song</h3>
                            <p className="founder-description">Ari is a Co-director of Team Ctrlfyp. He previously ran the animation studio Mechanical Apple and has worked on countless projects across film, games and music videos. Ari's been modding games from before computers were colour and has loved them since his first ever game rental: Faxanadu for NES.</p>
                        </div>
                    </div>
                    <div className="founder-ridong founder">
                        <div className="founder-info">
                            <h3 className="founder-name">Ridong Xiao</h3>
                            <p className="founder-description">Ridong is a Co-director of Ctrlfyp. He's been designing and building games for years, creating crazy worlds for people to poke around in and explore. He's loved games ever since his Dad found the wing boots for him in Zelda 2, and wants to recreate that experience of adventure and excitement for other people.</p>
                        </div>
                        <div className="portrait-wrapper">
                            <img className="portrait" src={JohnImage}></img>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutPage;