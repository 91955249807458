import React from 'react';
import Helmet from 'react-helmet';
import {
    withRouter, 
    Switch, 
    BrowserRouter as Router,
    Route,
    Redirect,
    NavLink,
}   from 'react-router-dom';

import {GetPost, GetBlank} from '../../../../services/api/blog-api';

import './post-page.scss';

class BlogPage extends React.Component{

    constructor(Props){
        super(Props);
  
        let postID = this.props.location.pathname.split('/')[1] ?? -1;    

        this.state = {
            postID: postID,
            post: this.props.post ?? GetBlank(),
        }

        
        console.log(window.location.origin + this.state.post.imageURL);
    }

    componentDidMount = () => {
        this.loadPost();
    }

    loadPost = () => {
        this.setState({

        });
    }

    render = () => {
        return (
            <Router>
                <Switch>
                    <Route>
                        <div className="post-page section">
                            <Helmet>
                                <title>{"Ctrlfyp - " + this.state.post.title}</title>
                            </Helmet>
                            <div>
                                <h1 className="title">{this.state.post.title}</h1>
                                <div className="header">
                                    <div className="tags-row">
                                        <h5 className="tags-title">Tags: </h5>
                                        <ul className="tags-list">
                                            {this.state.post.tags.map((tag) => {
                                                    return <p className="tag" key={tag}>{tag}</p>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="social-header">

                                    </div>
                                </div>
                                <div className="post-image">
                                    <img key={window.location.origin + this.state.post.imageURL} src={window.location.origin + this.state.post.imageURL} alt="post-image"></img>
                                </div>
                                <div className="post-post" >
                                    <div className="post-intro">
                                        <p>{this.state.post.intro}</p>
                                    </div>
                                    <div className="post-content" dangerouslySetInnerHTML={{__html: this.state.post.post}}></div>
                                </div>
                            </div>
                        </div>
                    </Route>
                    <Route path="">
                        <Redirect to=""></Redirect>
                    </Route>
                </Switch>
            </Router>
        )
    }
}

export default withRouter(BlogPage);