import React from 'react';

import {withRouter, NavLink} from 'react-router-dom';

import {GetPost, GetPosts} from '../../../../services/api/blog-api';
import Button from '@material-ui/core/Button';

import CtrlfypBanner from '../../../../assets/imgs/ctrlfyp-logo.png';

import './blog-section.scss';

class BlogSection extends React.Component{

    constructor(Props){
        super(Props);

        this.state = {
            postId: "",
            post: this.props.Post ?? {},
            currPostIndex: -1,
            posts: [],
        }

    }

    componentDidMount = () => {
        this.setState({
            post: GetPost(this.state.postId),
            posts: GetPosts(),
        })
    }

    render = () => {
        return (
            <div className="blog-section">
                <h2 className="subtitle">Recent Posts</h2>
                <ul className="post-listings">
                    {this.state.posts.map((post) => {
                        return (
                            <NavLink key={post.ID} to={"/blog/" + post.ID + "/" + post.title.replace(/ /g, "-").toLowerCase()}>
                                <li className="post-listing">
                                    <div className="post-image-wrapper">
                                        <img className="post-image" src={window.location.origin + CtrlfypBanner}></img>
                                    </div>
                                    <div className="post-info">
                                        <h4 className="post-tag">{post.tags[0]}</h4>
                                        <h3 className="post-title">{post.title}</h3>
                                        <p className="post-snippet">{post.intro}</p>
                                        <h4 className="post-author">{post.author}</h4>
                                        <h4 className="post-date">{post.date.toLocaleDateString()}</h4>
                                    </div>
                                </li>
                            </NavLink>
                        )
                    })}
                </ul>
                <Button>Load More</Button>  
            </div>
        )
    }
}

export default withRouter(BlogSection);