import React from 'react';
import {
    NavLink,
  } from "react-router-dom";

import './header.scss';

class Header extends React.Component{

    constructor(Props){
        super(Props);

        this.state = {
            activeItem: 'dashboard',
        }
    }

    render(){
        return (
            <div className="header-wrapper">
                <div className="nav-header">
                    <NavLink to="/">
                    <div className="logo"></div>
                    </NavLink>
                    <ul className="nav-links">
                        <li className={this.state.activeItem === 'About' ? 'active' : ""}>
                            <NavLink to="/About">About Us</NavLink>
                        </li>
                        <li className={this.state.activeItem === 'Works' ? 'active' : ""}>
                            <NavLink to="/Works">Our Works</NavLink>
                        </li>
                        <li className={this.state.activeItem === 'Careers' ? 'active' : ""}>
                            <NavLink to="/Careers">Careers</NavLink>
                        </li>
                        <li className={this.state.activeItem === 'Press' ? 'active' : ""}>
                            <NavLink to="/Press">Press</NavLink>
                        </li>
                        <li className={this.state.activeItem === 'Contact' ? 'active' : ""}>
                            <NavLink to="/Contact">Contact</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Header;