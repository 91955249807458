import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Title } from '@material-ui/icons';
import { Twitter, Instagram, YouTube } from '@material-ui/icons';

import '../scss/under-construction.component.scss';


type TimerInfo = {
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
}

const UnderConstructionComponent: React.FC = () => {

  const calculateTimeLeft = () => {
    const targetDate = new Date(2021, 12, 30);
    const difference = +targetDate - +new Date();
    let timeLeft = {} as TimerInfo;

      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      } as TimerInfo;
    ;

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = React.useState<TimerInfo>(calculateTimeLeft());

  React.useEffect(() => {
    const timer=setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  return (
    <div className='under-construction-component'>
      <Helmet>
        <Title>Under Construction</Title>
      </Helmet>
      <div className='under-construction-title'>
        <h2 className='title'>
          COMING SOON
        </h2>
        <div className='timer'>
          <div className="timer-container">
            <div className='timer-value'>
              { timeLeft.days.toString().padStart(2, '0')}
            </div>
            <div className='timer-text'>
              Days
            </div>
          </div>
          <div className="timer-container">
            <div className='timer-value'>
              { timeLeft.hours.toString().padStart(2, '0')}
            </div>
            <div className='timer-text'>
              Hours
            </div>
          </div>
          <div className="timer-container">
            <div className='timer-value'>
              { timeLeft.minutes.toString().padStart(2, '0')}
            </div>
            <div className='timer-text'>
              Minutes
            </div>
          </div>
          <div className="timer-container">
            <div className='timer-value'>
              { timeLeft.seconds.toString().padStart(2, '0')}
            </div>
            <div className='timer-text'>
              Seconds
            </div>
          </div>
        </div>
        <div>
          <ul className="social-links">
            <li>
              <a href="google.ca" className="twitter-link">
                <Twitter className="circle"></Twitter>
              </a>
            </li>
            <li>
              <a href="google.ca" className="instagram-link">
                <Instagram className="circle"></Instagram>
              </a>
            </li>
            <li>
              <a href="google.ca" className="youtube-link">
                <YouTube className="circle"></YouTube>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default UnderConstructionComponent;
