import React from 'react';
import Helmet from 'react-helmet';

import BlogSection from '../blog-section/blog-section';

import './landing-page.scss';

class LandingPage extends React.Component{

    constructor(Props){
        super(Props);

        this.state = {
            
        }
    }

    render() {

        /*
                           <div className="landing-intro">
                        <h1>Video Games</h1>
                        <img src={CtrlfypBanner}></img>
                        <img src={WorkingImage}></img>
                        <p>Happy Holidays Gang!

With Christmas close and Team Cherry about to take a few days off after an intensive year’s development, we’re gonna try and keep this update as snap-sharp as we can. Keep Reading…</p>
                    </div>
        */

        return (
            <div className="landing-page section">
                <Helmet>
                    <title>Ctrlfyp - Landing Page</title>
                    <meta name="description" content="Helmet application"/>
                </Helmet>
                <div className="landing-page">                   
                    <BlogSection></BlogSection>
                </div>
            </div>
        );
    }
}

export default LandingPage;