import React from 'react';
import Helmet from 'react-helmet';
import '../../../../services/api/jobs-api';

import Button from '@material-ui/core/Button';
import {
    NavLink,
  } from "react-router-dom";


import './job-description.scss';
import { GetJob } from '../../../../services/api/jobs-api';

class JobDescription extends React.Component{

    constructor(Props){
        super(Props);

        this.state = {
            jobId: this.props.jobId,
            job: {
                title: "",
                description: "",
            },
        }
    }

    componentDidMount = () => {
        this.loadJob(this.state.jobId);
    }

    loadJob = (jobId) => {
        this.setState({
            job: GetJob(jobId),
        })
    }

    apply = () => {

    }

    render = () => {
        return (
            <div className="job-description">
                <Helmet>
                    <title>{"Ctrlfyp - " + this.state.job.title}</title>
                </Helmet>
                <div className="job-display">
                    <div className="job-photo"></div>
                </div>
                <div className="job-info">
                    <div className="job-header">
                        <h2 className="job-title">{this.state.job.title.toUpperCase()}</h2>
                        <h4>{this.state.job.employmentStatus}</h4>
                    </div>
                    <div className="job-requirements" dangerouslySetInnerHTML={{__html: this.state.job.description}}></div>
                    <div className="action-row">
                        <NavLink to="/careers">
                            <Button variant="contained" onClick={() => this.apply()}>
                                Apply
                            </Button>
                        </NavLink>                        
                        <NavLink to="/careers">
                            <Button variant="contained" onClick={() => this.apply()}>
                                Back
                            </Button>
                        </NavLink>
                    </div>
                </div>

            </div>
        );
    }

}

export default JobDescription;