import React from 'react';

import './404-page.scss';
import Picture404 from '../../../assets/imgs/404-picture.jpg';

class Page404 extends React.Component{

    constructor(Props){
        super(Props);

        this.state = {

        }
    }

    render(){
        return (
            <div className="section error-404">
                <img src={Picture404} className="error-picture"></img>
            </div>
        );
    }

}

export default Page404;