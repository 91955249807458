import React from 'react';
import Helmet from 'react-helmet';
import { NavLink } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import Modal from '@material-ui/core/Modal';

import './press-page.scss';

import Screenshot1 from '../../../../assets/imgs/header.png';

class PressPage extends React.Component{

    constructor(Props){
        super(Props);
        this.state = {
            //<Link to="route" target="_blank" onClick={(event) => {event.preventDefault(); window.open(this.makeHref("route"));}} />
            screenshots: [
                Screenshot1, Screenshot1, Screenshot1, Screenshot1, Screenshot1,
            ],
            logos: [

            ]
        }
    }

    showScreenshot = () => {

    }

    render(){

        return (
            <div className="press-page section">
                <Helmet>
                    <title>Ctrlfyp - Press</title>
                    <meta name="description" content="Helmet application" />
                </Helmet>
                <div className="banner">
                </div>
                <div className="press-header">
                    <div className="game-description">
                        <p>Cuphead is a classic run and gun action game heavily focused on boss battles. Inspired by cartoons of the 1930s, the visuals and audio are painstakingly created with the same techniques of the era, i.e. traditional hand drawn cel animation, watercolor backgrounds, and original jazz recordings. Play as Cuphead or Mugman (in single player or local co-op) as you traverse strange worlds, acquire new weapons, learn powerful super moves, and discover hidden secrets while you try to pay your debt back to the devil!
                        </p>
                    </div>
                </div>
                <Divider/>
                <div className="fact-sheet">
                    <div className="facts">
                        <h3 className="section-title">Fact Sheet</h3>
                        <ul>
                            <li>
                                <h4>Developer/Publisher</h4>
                                <p>Ctrlfyp</p>
                            </li>
                            <li>
                                <h4>Release Date</h4>
                                <p>TBD</p>
                            </li>
                            <li>
                                <h4>Platforms</h4>
                                <p>Steam, MacOS</p>
                            </li>
                            <li>
                                <h4>Website</h4>
                                <p>https://mars.com</p>
                            </li>
                            <li>
                                <h4>Price</h4>
                                <p>$19.99 USD</p>
                            </li>
                        </ul>
                    </div>
                    <div className="game-description">
                        <h3 className="section-title">Description</h3>
                        <p>Welcome to Chasm, an action-adventure game in which you play a new recruit undertaking your first mission for the Guildean Kingdom. Thrilled to prove your worth as a knight, you track strange rumors that a mine vital to the Kingdom has been shut down. But what you discover in the mining town is worse than you imagined: The townspeople have disappeared, kidnapped by supernatural creatures emerging from the depths. Honor-bound to solve the mystery and restore peace to the Kingdom, you embark upon an epic adventure, with deadly battles against cunning monsters, exploration of ancient catacombs and castles, and powerful new equipment hidden at every turn. Though the overall story is the same for all players, your hero's journey will be unique: each of the rooms has been hand-designed, and behind the scenes Chasm stitches these rooms together into a one-of-a-kind world map that will be your own.
                        </p>
                    </div>
                </div>
                <Divider/>
                <div className="features">
                    <div className="credits">
                        <h3 className="section-title">Features</h3>
                        <ul>
                            <li>
                                <h4>Explore six massive areas procedurally-assembled from hand-crafted rooms</h4>
                                <h4>Enjoy challenging retro gameplay and authentic pixel art (384x216 native res.)</h4>
                                <h4>Battle massive bosses and discover new abilities to reach previously inaccessible areas</h4>
                                <h4>Customize your character by equipping armor, weapons, and spells</h4>
                                <h4>Coming to PS4/Vita and PC (Win/Mac/Linux via Steam and Humble)</h4>
                            </li>
                        </ul>
                    </div>
                </div>
                <Divider/>
                <div className="trailer">
                    <h3 className="section-title">Trailer</h3>
                    <div className="press-video">
                        <iframe  src='https://www.youtube.com/embed/E7wJTI-1dvQ'
                            frameBorder='0'
                            allow='autoplay; encrypted-media'
                            allowFullScreen
                            title='video'
                        />
                    </div>
                </div>
                <Divider/>
                <div className="screenshots">
                    <h3 className="section-title">Screenshots</h3>
                    <div className="image-grid">
                        {
                            this.state.screenshots.map((screenshot) => {
                                return (<div className="gallery-wrapper"><button key={screenshot} onClick={() => this.showScreenshot()}><img key={screenshot} src={screenshot}></img></button></div>)
                            })
                        }
                    </div>
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={false}
                        onClose={() => {console.log('hi')}}
                    >
                        <div>
                        <h2 id="simple-modal-title">Text in a modal</h2>
                        <p id="simple-modal-description">
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </p>
                        </div>
                    </Modal>
                </div>
                <Divider/>
                <div className="assets">
                    <h3 className="section-title">Logo & Icon</h3>
                    <div className="image-grid">
                        {
                            this.state.screenshots.map((screenshot) => {
                                return (<div className="gallery-wrapper"><button key={screenshot} onClick={() => this.showScreenshot()}><img key={screenshot} src={screenshot}></img></button></div>)
                            })
                        }
                    </div>
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={false}
                        onClose={() => {console.log('hi')}}
                    >
                        <div>
                        <h2 id="simple-modal-title">Text in a modal</h2>
                        <p id="simple-modal-description">
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </p>
                        </div>
                    </Modal>
                </div>
                <Divider/>
                <div className="additional-information">
                    <h3 className="section-title">Additional Resources</h3>
                    <NavLink to="https://google.ca">Google</NavLink>
                </div>
                <Divider/>
                <div className="studio-information">
                    <div className="credits">
                        <h3 className="section-title">Project Mars Credits</h3>
                        <ul>
                            <li>
                                <h4>John Song</h4>
                                <p>Lead Developer</p>
                            </li>
                            <li>
                                <h4>Ridong Xiao</h4>
                                <p>Art Director</p>
                            </li>
                        </ul>
                    </div>
                    <div className="contact-information">
                        <h3 className="section-title">Contact</h3>
                        <ul>
                            <li>
                                <h4>Inquiries</h4>
                                <p>contact@ctrlfyp.com</p>
                            </li>
                            <li>
                                <h4>Twitter</h4>
                                <a href="https://twitter.com/ctrlfyp">https://twitter.com/ctrlfyp</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default PressPage;