import React from 'react';
import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
  } from "react-router-dom";
import Helmet from 'react-helmet';

import Header from '../components/header/header.js';
import Footer from '../components/footer/footer.js';
import BlogPage from '../modules/landing/scenes/blog-page/blog-page.js';
import AboutPage from '../modules/about/scenes/about-page/about-page.js';
import WorksPage from '../modules/works/scenes/works-page/works-page';
import LandingPage from '../modules/landing/scenes/landing-page/landing-page.js';
import CareersPage from '../modules/careers/scenes/careers-page/careers-page';
import PressPage from '../modules/press/scenes/press-page/press-page.js';
import ContactPage from '../modules/contact/scenes/contact-page/contact-page';
import Page404 from '../modules/error/scenes/404-page';
import { UnderConstructionComponent } from '../modules/under-construction/index';

class App extends React.Component{

  constructor(Props){
    super(Props);
  }

  render(){

    let routes = 
    [
      {
        route: <Route path="/press" component={() => {window.location.href = 'https://ctrlfyp.com/press'; return null;}}></Route>, path: "/press"
      },
      {
        route: <Route path="/"></Route>, path: "", component: <UnderConstructionComponent></UnderConstructionComponent>,
      },
      {
        route: <Route exact path="/"></Route>, path: "", component: <LandingPage></LandingPage>,
      },
      {
        route: <Route path="/blog"></Route>, path: "/blog", component: <BlogPage></BlogPage>
      },
      {
        route: <Route path="/about"></Route>, path: "/about", component: <AboutPage></AboutPage>
      },
      {
        route: <Route path="/works"></Route>, path: "/works", component: <WorksPage></WorksPage>
      },
      {
        route: <Route path="/careers"></Route>, path: "/careers", component: <CareersPage></CareersPage>
      },
      {
        route: <Route path="/contact"></Route>, path: "/contact", component: <ContactPage></ContactPage>
      },
      {
        route: <Route path="/404"></Route>, path: "/404", component: <Page404></Page404>
      },
      {
        route: <Route path="/"></Route>, path: "default", component: <Redirect to="/404"></Redirect>
      },
    ]
 
    return (
      <Router>
        <div className="App">
        <Helmet>
          <title>Ctrlfyp</title>
          <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
          <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        </Helmet>
        <Switch>
          {routes.map((r) => {
            return <r.route.type {...r.route.props} key={r.path}>
              {r.component && <r.component.type {...r.route.props}/>}
                
            </r.route.type>
          })}
        </Switch>
      </div>
    </Router>
    )    
  }
}

export default App;
