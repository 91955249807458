import React from 'react';
import Helmet from 'react-helmet'

import {
    Link,
  } from "react-router-dom"
import './works-page.scss';

import Button from '@material-ui/core/Button';
import { Twitter, Instagram, YouTube, LinkedIn} from '@material-ui/icons';
import Screenshot1 from '../../../../assets/imgs/header.png';
import Screenshot2 from '../../../../assets/imgs/test-image-002.png';


class LandingPage extends React.Component{

    constructor(Props){
        super(Props);

        this.state = {
            
        }
    }

    render() {

        /*
            <div className="links">
                            <div className="social-links-wrapper">
                                <h3>Social Links</h3>
                                <ul className="social-links">
                                    <li>
                                        <Link to="google.ca" className="twitter-link">
                                            <Twitter className="circle"></Twitter>
                                            <h3 className="link-name">Ctrlfyp on Twitter</h3>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="google.ca" className="discord-link">
                                            <LinkedIn className="circle"></LinkedIn>
                                            <h3 className="link-name">Ctrlfyp on Linkedin</h3>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="google.ca" className="instagram-link">
                                            <Instagram className="circle"></Instagram>
                                            <h3 className="link-name">Ctrlfyp on Instagram</h3>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="google.ca" className="youtube-link">
                                            <YouTube className="circle"></YouTube>
                                            <h3 className="link-name">Ctrlfyp on Youtube</h3>
                                        </Link>
                                    </li>
                                </ul>
                                <Link to="https://projectmars.com" className="offical-site">Offical Site</Link>
                            </div>
                            <div className="store-links-wrapper">
                                <h3>Store Links</h3>
                                <ul className="store-links">
                                    <li>
                                        <Link to="google.ca" className="twitter-link">
                                            <Twitter className="circle"></Twitter>
                                            <h3 className="link-name">Ctrlfyp on Twitter</h3>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="google.ca" className="discord-link">
                                            <LinkedIn className="circle"></LinkedIn>
                                            <h3 className="link-name">Ctrlfyp on Linkedin</h3>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="google.ca" className="instagram-link">
                                            <Instagram className="circle"></Instagram>
                                            <h3 className="link-name">Ctrlfyp on Instagram</h3>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="google.ca" className="youtube-link">
                                            <YouTube className="circle"></YouTube>
                                            <h3 className="link-name">Ctrlfyp on Youtube</h3>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    
        */

        return (
            <div className="works-page section">
                <Helmet>
                    <title>Ctrlfyp - Past Works</title>
                </Helmet>
                <div className="summary">
                    <h1 className="title">Past Works</h1>
                    <p className="subtitle summary-description">
                        Below you can find a list of our past works
                    </p>
                </div>
                <div className="past-works">
                    <div className="project-mars work">
                        <div className="game-image-wrapper">
                            <img className="banner" src={Screenshot2} alt="Company Banner"></img>                      
                        </div>
                        <div className="game-info">
                            <h3>Project Mars</h3>
                            <p className="work-description">
                            Discover a vast, haunted kingdom in Hollow Knight: Silksong! The sequel to the award winning action-adventure. Explore, fight and survive as you ascend to the peak of a land ruled by silk and song.
                            </p>
                            <p>
                            Currently in development for Pc, Mac, Linux and Nintendo Switch.
                            </p>
                            <a className="game-site-link" target="_blank" href="https://projectmars.com/play"><Button>Visit The Offical Site</Button></a>
                        </div>
                    </div>
                    <div className="project-tiles work">
                        <div className="game-image-wrapper">
                            <img className="banner" src={Screenshot2} alt="Company Banner"></img>                      
                        </div>
                        <div className="game-info">
                            <h3>Divide The Tiles</h3>
                            <p className="work-description">
                            Discover a vast, haunted kingdom in Hollow Knight: Silksong! The sequel to the award winning action-adventure. Explore, fight and survive as you ascend to the peak of a land ruled by silk and song.
                            </p>
                            <p>
                            Currently in development for Pc, Mac, Linux and Nintendo Switch.
                            </p>
                            <a className="game-site-link" target="_blank" href="https://m.apkpure.com/divide-the-tiles/com.CtrlFYourPassion.DivideTheTiles"><Button>Visit The Offical Site</Button></a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingPage;